import { fetchSH } from '@/core/api'
import { useAuth, useTenant } from '@/core'
import { computed } from 'vue'
const { user } = useAuth()
const { tenant } = useTenant()
const exercisesProductsIds = computed<string[]>(() =>
  tenant.value?.eportalProducts
    .filter((product) => product.type === 'exercises')
    .map((product) => product.id),
)

export function getProductLangConst(productId) {
  return tenant.value?.eportalProducts.find(({ id }) => id === productId)
    .langConst
}

export const getStudents = async () => {
  const teacher = await fetchSH(
    `institutions/${user.value.raw._json.affiliation}/teachers/${user.value.providerId}`,
  )

  const res = await fetchSH(
    `institutions/${user.value.raw._json.affiliation}/teachers/${user.value.providerId}/students`,
  )

  const students = []

  res.data.forEach((student) => {
    const existingStudent = students.filter((st) => st.id === student.id)[0]

    const teacherAccessGroup = teacher.accessGroups.filter(
      (g) => g.id === student.accessGroup.id,
    )[0]
    if (teacherAccessGroup) {
      student.accessGroup.products = teacherAccessGroup.products.filter(
        (product) => exercisesProductsIds.value.includes(product.id),
      )
    }

    if (existingStudent && student.accessGroup.products.length) {
      existingStudent.accessGroups.push(student.accessGroup)
    } else {
      student.accessGroups = []
      if (student.accessGroup.products.length) {
        student.accessGroups.push(student.accessGroup)
      }
      delete student.accessGroup
      students.push(student)
    }
  })

  return students
}
