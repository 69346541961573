import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-hidden", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("section", {
    "aria-hidden": !_ctx.isActive,
    class: _normalizeClass(['c-tab__panel', { 'c-tab__panel': _ctx.isDisabled }]),
    id: _ctx.computedId,
    role: "tabpanel"
  }, [
    (_ctx.isActive)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.isActive]
  ])
}