
import { provide, ref, reactive, onMounted, defineComponent } from 'vue'

import FHButton from '@/components/TheButton.vue'
import { ITab, ITabsProvider } from 'types/interface/Tabs'

export default defineComponent({
  name: 'Tabs',

  components: {
    FHButton,
  },

  emits: ['changed'],

  props: {
    ariaLabelTablist: String,
    tabable: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const tablist = ref([])
    const state = reactive<ITabsProvider>({
      activeTab: '',
      activeTabHash: '',
      activeTabIndex: 0,
      tabs: [],
    })
    provide<ITabsProvider>('tabsProvider', state)
    const findTab = (hash: string): ITab => {
      return state.tabs.find((tab) => tab.hash === hash)
    }
    const checkActiveTab = (hash: string): boolean => {
      return state.activeTabHash === hash
    }
    const setActive = (hash: string): void => {
      const selectedTab = findTab(hash)

      if (!selectedTab) return
      if (selectedTab.isDisabled) return

      emit('changed', { tab: selectedTab })
      state.activeTabHash = selectedTab.hash
      state.activeTabIndex = getTabIndex(selectedTab.hash)
    }

    const handleTabsKeyEvent = (ev: KeyboardEvent): void => {
      if (!state.activeTabHash.length) {
        return
      }
      const focusActive = (): void => {
        tablist.value[state.activeTabHash].$el.focus()
      }

      switch (ev.key) {
        case 'ArrowLeft':
          previousTab()
          focusActive()
          break
        case 'ArrowRight':
          nextTab()
          focusActive()
          break
        case 'Home':
          firstTab()
          focusActive()
          break
        case 'End':
          lastTab()
          focusActive()
          break
        default:
      }
    }

    const nextTab = (): void => {
      for (let i = state.activeTabIndex + 1; i < state.tabs.length; i++) {
        if (!state.tabs[i].isDisabled) {
          setActive(state.tabs[i].hash)
          return
        }
      }
    }

    const previousTab = (): void => {
      for (let i = state.activeTabIndex - 1; i >= 0; i--) {
        if (!state.tabs[i].isDisabled) {
          setActive(state.tabs[i].hash)
          return
        }
      }
    }

    const firstTab = (): void => {
      for (let i = 0; i < state.tabs.length; i++) {
        if (!state.tabs[i].isDisabled) {
          setActive(state.tabs[i].hash)
          return
        }
      }
    }

    const lastTab = (): void => {
      for (let i = state.tabs.length - 1; i >= 0; i--) {
        if (!state.tabs[i].isDisabled) {
          setActive(state.tabs[i].hash)
          return
        }
      }
    }

    const getTabIndex = (hash: string): number => {
      const tab = findTab(hash)

      return state.tabs.indexOf(tab)
    }

    onMounted(() => {
      if (state.tabs.length) {
        firstTab()
      }
    })

    return {
      state,
      tablist,
      checkActiveTab,
      setActive,
      handleTabsKeyEvent,
    }
  },
})
