import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = { class: "c-tabs__list-wrapper" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "c-tabs__panels-wrapper" }
const _hoisted_5 = { class: "c-tabs__panels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FHButton = _resolveComponent("FHButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "before-tablist"),
      _createElementVNode("ul", {
        class: "c-tabs__tablist",
        role: "tablist",
        "aria-label": _ctx.ariaLabelTablist
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tab.hash,
            class: _normalizeClass(["c-tabs__item", { 'c-tabs__item--disabled': tab.isDisabled }]),
            role: "presentation"
          }, [
            _createVNode(_component_FHButton, {
              innerHTML: tab.name,
              class: _normalizeClass(["c-tabs__btn", { 'c-tabs__btn--active': _ctx.checkActiveTab(tab.hash) }]),
              role: "tab",
              ref: 
              (el) => {
                if (el) _ctx.tablist[tab.hash] = el
              }
            ,
              disabled: tab.isDisabled,
              "aria-controls": tab.hash,
              "aria-selected": _ctx.checkActiveTab(tab.hash) ? 'true' : 'false',
              tabindex: !_ctx.tabable ? (_ctx.checkActiveTab(tab.hash) ? '0' : '-1') : '0',
              onClick: ($event: any) => (_ctx.setActive(tab.hash)),
              onKeyup: _ctx.handleTabsKeyEvent
            }, null, 8, ["innerHTML", "disabled", "aria-controls", "aria-selected", "class", "tabindex", "onClick", "onKeyup"])
          ], 2))
        }), 128))
      ], 8, _hoisted_3),
      _renderSlot(_ctx.$slots, "after-tablist")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "before-panels"),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _renderSlot(_ctx.$slots, "after-panels")
    ])
  ]))
}