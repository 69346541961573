import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-dashboard-themes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterThemes = _resolveComponent("FilterThemes")!
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_TheButton = _resolveComponent("TheButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_FilterThemes, {
      availableExercises: _ctx.availableExercises,
      onFilterChange: _ctx.onFilterChange
    }, null, 8, ["availableExercises", "onFilterChange"]),
    _createVNode(_component_TheHeading, {
      class: "c-dashboard-themes__heading",
      level: "h2"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('VIEW_DASHBOARD_THEMES_LIST')), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableExercises, (subject) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "c-dashboard-themes__subject",
        key: subject.id
      }, [
        _createVNode(_component_TheHeading, {
          class: "c-dashboard-themes__subject-name",
          level: "h3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(subject.name), 1)
          ]),
          _: 2
        }, 1024),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subject.children, (gripLevel) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "c-dashboard-themes__grip-level",
            key: gripLevel.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gripLevel.children, (theme, themeId) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "c-dashboard-themes__theme",
                key: themeId
              }, [
                _createVNode(_component_TheHeading, {
                  class: "c-dashboard-themes__theme-name",
                  level: "h4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(gripLevel.name) + " / " + _toDisplayString(themeId + 1) + ". " + _toDisplayString(theme.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_TheButton, {
                  type: "theme",
                  onClick: ($event: any) => (_ctx.onShowDetails(subject.name, gripLevel.name, theme.name))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('VIEW_DASHBOARD_SEE_MORE')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128))
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}