
import { ref, computed, defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import TheHeading from '@/components/TheHeading.vue'
import DataGrid from '@/components/DataGrid.vue'
import FilterStudents from '@/components/FilterStudents.vue'
import { getStudents, getProductLangConst } from '@/services/teacherData'
import useModal from '@/composables/useModal'

export default defineComponent({
  components: {
    DataGrid,
    TheHeading,
    FilterStudents,
  },

  setup() {
    const { setModal } = useModal()
    const gridSortKey = ref('firstName')
    const gridSortOrder = ref('ascending')
    const selectedValue = ref([])
    const { locale, t } = useI18n()
    const students = ref([])

    const columns = ref([
      {
        key: 'firstName',
        label: `${t('DASHBOARD_GRID_NAME')}`,
        sortable: true,
        editableRows: true,
      },
      {
        key: 'lastName',
        label: `${t('DASHBOARD_GRID_SURNAME')}`,
        sortable: true,
      },
      {
        key: 'subjects',
        label: `${t('DASHBOARD_GRID_SUBJECT')}`,
        sortable: true,
      },
      {
        key: 'group',
        label: `${t('DASHBOARD_GRID_CLASS')}`,
        sortable: true,
      },
      {
        key: 'groupNames',
        label: `${t('DASHBOARD_GRID_GROUPS')}`,
        sortable: true,
      },
    ])

    onMounted(async () => {
      const studentsData = await getStudents()
      students.value = studentsData
        .filter((student) => student.accessGroups.length)
        .map((student) => ({
          ...student,
          groupNames: student.accessGroups
            .map((group) => group.name)
            .sort()
            .join(', '),
          subjects: student.accessGroups
            .map((group) =>
              group.products
                .map((product) => t(getProductLangConst(product.id)))
                .sort()
                .join(', '),
            )
            .filter((name) => name)
            .join(', '),
        }))
    })

    const rowsSorted = computed(() => {
      // string sort for now, if remote API sort will be available - change functionality
      const sorted = [...students.value].sort((a, b) =>
        a[gridSortKey.value].localeCompare(b[gridSortKey.value], locale.value),
      )
      if (gridSortOrder.value === 'ascending') {
        return sorted
      } else {
        return sorted.reverse()
      }
    })
    const onUpdateSortKey = (val) => {
      gridSortKey.value = val
    }
    const onUpdateSortOrder = (val) => {
      gridSortOrder.value = val
    }

    function onUpdateSelect(val) {
      selectedValue.value = val
    }

    function onRowSelected(rowData) {
      setModal('ModalDashboardStudent', {
        content: {
          student: rowData,
        },
        type: 'theme',
      })
    }

    return {
      columns,
      gridSortKey,
      rowsSorted,
      onUpdateSortKey,
      onUpdateSortOrder,
      onUpdateSelect,
      onRowSelected,
      selectedValue,
    }
  },
})
