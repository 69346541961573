
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useTenant, useBreadcrumbs } from '@/core'

import TheHeading from '@/components/TheHeading.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import TheButton from '@/components/TheButton.vue'
import Tabs from '@/components/Tabs.vue'
import Tab from '@/components/Tab.vue'
import DashboardStudents from '@/components/DashboardStudents.vue'
import DashboardThemes from '@/components/DashboardThemes.vue'

const HIDE_THEMES = true

export default defineComponent({
  name: 'Dashboard',

  components: {
    TheHeading,
    Breadcrumbs,
    TheButton,
    Tabs,
    Tab,
    DashboardStudents,
    DashboardThemes,
  },

  setup() {
    const { t } = useI18n()
    const { tenant } = useTenant()
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()

    clearBreadcrumbs()
    setBreadcrumbs([{ name: t('VIEW_DASHBOARD_HEADING') }])

    const onChangeTab = () => {
      //
    }

    useHead({
      title: computed(
        () => `${t('VIEW_DASHBOARD_HEADING')} - ${tenant.value.name}`,
      ),
    })

    return {
      onChangeTab,
      HIDE_THEMES,
    }
  },
})
