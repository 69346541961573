
import { computed, onBeforeMount, defineComponent } from 'vue'
import { useStructures } from '@/core'
import useAccessCheck from '@/composables/useAccessCheck'
import FilterThemes from '@/components/FilterThemes.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import useModal from '@/composables/useModal'

export default defineComponent({
  name: 'DashboardThemes',

  components: {
    FilterThemes,
    TheHeading,
    TheButton,
  },

  setup() {
    const exercisesSlugPath = 'grip/oppgaver'
    const {
      fetchStructuresNode,
      fetchStructuresChildren,
      getStructuresBySlugPath,
      getStructuresChildrens,
    } = useStructures()

    const { showErudioProduct } = useAccessCheck()
    const exercisesParent = computed(() =>
      getStructuresBySlugPath(exercisesSlugPath),
    )
    const availableExercises = computed(() =>
      getStructuresChildrens(exercisesParent.value.id, 'DIRECTORY')
        .filter((subject) => showErudioProduct(subject.id))
        .map((subject) => {
          subject.children = getStructuresChildrens(subject.id, 'DIRECTORY')

          subject.children.forEach((gripLevel) => {
            gripLevel.children = getStructuresChildrens(
              gripLevel.id,
              'DIRECTORY',
            )
          })

          return subject
        }),
    )

    // eslint-disable-next-line
    const onFilterChange = (event: {
      category: string
      value: string
    }): void => {
      // handle the list filtering based on the data from event
    }

    const { setModal } = useModal()
    const onShowDetails = (subject, gripLevel, theme) => {
      setModal('ModalDashboardTheme', {
        content: {
          subject,
          gripLevel,
          theme,
        },
        type: 'theme',
      })
    }

    onBeforeMount(async () => {
      await fetchStructuresNode(exercisesSlugPath)
      await fetchStructuresChildren(exercisesSlugPath, {
        limit: 1000,
        filter: `{"type": "DIRECTORY"}`,
      })

      await Promise.all(
        availableExercises.value.map(async (subject) => {
          if (subject.type === 'DIRECTORY') {
            await fetchStructuresChildren(subject.slugPath, { limit: 100 })
          }

          return subject
        }),
      )

      await Promise.all(
        availableExercises.value.map((subject) => {
          subject.children.map(async (gripLevel) => {
            await fetchStructuresChildren(gripLevel.slugPath, { limit: 100 })

            return gripLevel
          })

          return subject
        }),
      )
    })

    return {
      availableExercises,
      onFilterChange,
      onShowDetails,
    }
  },
})
