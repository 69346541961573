import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterStudents = _resolveComponent("FilterStudents")!
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_DataGrid = _resolveComponent("DataGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createBlock(_component_FilterStudents, {
          key: 0,
          onChangeValue: _ctx.onUpdateSelect
        }, null, 8, ["onChangeValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TheHeading, {
      id: "students-list-label",
      level: "h2",
      class: "c-dashboard-students__heading"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_STUDENTS_LIST_HEADING')), 1)
      ]),
      _: 1
    }),
    (_ctx.rowsSorted.length)
      ? (_openBlock(), _createBlock(_component_DataGrid, {
          key: 1,
          "aria-labelledby": "students-list-label",
          rows: _ctx.rowsSorted,
          columns: _ctx.columns,
          "initial-sort-key": _ctx.gridSortKey,
          "focusable-head-cells": "",
          "focusable-row-cells": "",
          "onUpdate:sortKey": _ctx.onUpdateSortKey,
          "onUpdate:sortOrder": _ctx.onUpdateSortOrder,
          onRowSelected: _ctx.onRowSelected
        }, null, 8, ["rows", "columns", "initial-sort-key", "onUpdate:sortKey", "onUpdate:sortOrder", "onRowSelected"]))
      : _createCommentVNode("", true)
  ], 64))
}