
import { inject, ref, watch, onBeforeMount, defineComponent } from 'vue'
import { ITabsProvider } from 'types/interface/Tabs'

export default defineComponent({
  name: 'Tab',

  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const tabsProvider = inject<ITabsProvider>('tabsProvider')
    const computedId = props.id || props.name.toLowerCase().replace(/ /g, '-')
    const hash = props.isDisabled ? '#' : `#${computedId}`
    const isActive = ref(false)
    watch(
      () => tabsProvider.activeTabHash,
      () => {
        isActive.value = hash === tabsProvider.activeTabHash
      },
    )
    onBeforeMount(() => {
      tabsProvider.tabs.push({
        name: props.name,
        isDisabled: props.isDisabled,
        hash: hash,
      })
    })
    return {
      isActive,
      computedId,
      hash,
    }
  },
})
